import React, { useState, useEffect } from 'react';

function CurrentTime() {
    const formatTime = (date) => {
        let hours = date.getHours();
        const minutes = date.getMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';

        hours = hours % 12; // Convert hour from 24-hour to 12-hour format
        hours = hours ? hours : 12; // The hour '0' should be '12'
        const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes; // Add leading zero to minutes if needed

        return `${hours}:${formattedMinutes} ${ampm}`;
    };

    const [time, setTime] = useState(() => formatTime(new Date()));

    useEffect(() => {
        const timer = setInterval(() => {
            setTime(formatTime(new Date()));
        }, 60000); // Update every minute

        return () => clearInterval(timer); // Cleanup on unmount
    }, []);

    return <span>{time}</span>;
}

export default CurrentTime;
