import React from 'react';
import './WhatsAppButton.css'; // Make sure you create this CSS file

// Replace the link with your WhatsApp number or API link
const WHATSAPP_LINK = 'https://wa.me/+447946707154'; 

function WhatsAppButton() {
  return (
    <a href={WHATSAPP_LINK} target="_blank" rel="noopener noreferrer" className="whatsapp-button">
      <img src={require('./WhatsAppButtonWhiteMedium.png')} alt="WhatsApp" />
    </a>
  );
}

export default WhatsAppButton;
