import React, { useEffect } from 'react';
import './Header.css';
import backgroundImage from './mountain-background.jpeg';
import myImage from './me1.png';
import cloud1 from './cloud1.png';
import cloud2 from './cloud2.png';

function Header() {
  useEffect(() => {
    const handleScroll = () => {
      const header = document.querySelector('.header');
      const scrollPosition = window.scrollY;
      const windowHeight = window.innerHeight;
      const isMobile = window.innerWidth <= 768;

      // Set different scroll thresholds for desktop and mobile
      const scrollThreshold = isMobile ? windowHeight * 0.02 : windowHeight * 0.18;

      // Add animation class if scrolled past the threshold
      if (scrollPosition > scrollThreshold) {
        header.classList.add('animate-clouds');
      } else {
        header.classList.remove('animate-clouds');
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <header className="header" style={{ backgroundImage: `url(${backgroundImage})` }}>
      <h1>Charlie Peters - Web Developer</h1>
      <p>Creating innovative solutions for your web needs.</p>
      <a href="#contact" className="cta-button">I Need a Website</a>
      <img src={myImage} alt="Charlie Peters" className="header-image" />
      <img src={cloud1} alt="Cloud 1" className="cloud cloud-left" />
      <img src={cloud2} alt="Cloud 2" className="cloud cloud-right" />
    </header>
  );
}

export default Header;
