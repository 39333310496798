import React, { useEffect } from 'react';
import './Services.css';
import CurrentTime from './CurrentTime'; // Import the CurrentTime component
import myImage from './me1.png'; // Path to your image

function Services() {
  useEffect(() => {
    const handleScroll = () => {
      const element = document.querySelector('.myName');
      if (!element) return; // Guard against missing element

      const rect = element.getBoundingClientRect();
      const isVisible = rect.top < window.innerHeight && rect.bottom >= 0;

      if (isVisible && !element.classList.contains('bounce')) {
        element.classList.add('bounce');
      }
    };

    // Add scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Initial check in case the element is already in view
    handleScroll();

    // Cleanup function to remove the scroll listener
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <section id="services" className="services">
      <div className="text-container">
        <h2>
          I'm <span className="myName">Charlie</span>, a Freelance web developer based in London.
        </h2>
        <p>
          Pause for a moment. It’s <CurrentTime /> and I know you’re probably itching to get back to that intriguing book or binge-watching your latest series.
          You’ve probably sifted through countless Web Designer profiles, each promising the moon and stars. But before you dive back into your search,
          let’s make a deal: give this one last look. I’m confident that once you see what I have to offer, you’ll find this is the final stop on your journey
          to a standout digital presence.
          <br /><br />
          Hi, I'm Charlie. I specialize in crafting high-performing and visually attractive websites for small and medium sized businesses.
          I deliver bespoke solutions tailored to your unique needs and challenges, for <strong>prices as low as £100.</strong> I can deliver a fully functional website
          for your business in less than a week, and the best part? <br /><br />
          <strong>You pay NOTHING until you are happy with the design!</strong>
        </p>
      </div>
      <div className="image-container">
        <img src={myImage} alt="Charlie Peters" />
      </div>
    </section>
  );
}

export default Services;
