import React from 'react';
import { useForm, ValidationError } from '@formspree/react';
import './Contact.css'; // Ensure you have a CSS file for styling

function Contact() {
  const [state, handleSubmit] = useForm("mjkbrboj"); // Use your Formspree form ID

  if (state.succeeded) {
    return <p>Thank you for reaching out! I'll get back to you soon.</p>;
  }

  return (
    <div id="contact" className="contact-container"> {/* Add ID here */}
      <h2>Fill in the form below for your free design!</h2>
      <form onSubmit={handleSubmit} className="contact-form">
        <label htmlFor="name">
          Your Name:
        </label>
        <input
          id="name"
          type="text"
          name="name"
          required
        />
        <ValidationError
          prefix="Name"
          field="name"
          errors={state.errors}
        />

<label htmlFor="email">
          Your Email Address:
        </label>
        <input
          id="email"
          type="text"
          name="email"
          required
        />
        <ValidationError
          prefix="Email"
          field="email"
          errors={state.errors}
        />

<label htmlFor="number">
          Your Mobile Number:
        </label>
        <input
          id="number"
          type="text"
          name="number"
          required
        />
        <ValidationError
          prefix="Number"
          field="number"
          errors={state.errors}
        />


        <label htmlFor="description">
          Briefly describe your business and any ideas you had in mind for a website:
        </label>
        <textarea
          id="description"
          name="description"
          required
        />
        <ValidationError
          prefix="Description"
          field="description"
          errors={state.errors}
        />

        <button type="submit" disabled={state.submitting}>
          Submit
        </button>
      </form>
    </div>
  );
}

export default Contact;
