import React from 'react';
import { Analytics } from "@vercel/analytics/react";
import Navbar from './components/Navbar';
import Header from './components/Header';
import Services from './components/Services';
import Contact from './components/Contact';
import WhatsAppButton from './components/WhatsAppButton'; // Import the WhatsAppButton component
function App() {
  return (
    <div>
      <Navbar />
      <Header />
      <Services />
      <Contact />
      <WhatsAppButton /> {/* Include the WhatsAppButton here */}
      <Analytics />
    </div>
  );
}

export default App;
